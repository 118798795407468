<template>
  <div>
    <el-row style="margin-bottom: 20px">
      <el-col :span="4">
        <el-select v-model="ruleForm.mk_id" placeholder="请选择市场">
          <el-option
            v-for="item in marketid"
            :key="item.id"
            :label="item.comp_name"
            :value="item.id"

          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-button type="success" @click="init">查询</el-button>
      </el-col>
    </el-row>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="id" label="编号"> </el-table-column>
      <el-table-column prop="sf_title" label="设备名称">
      </el-table-column>
      <el-table-column prop="name" label="设施负责人">
      </el-table-column>

      <el-table-column prop="tel" label="负责人电话"> </el-table-column>
      <el-table-column prop="market_information.comp_name" label="市场名称">
      </el-table-column>
      <el-table-column prop="address" label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="success" @click="query(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex;justify-content:
		center;align-items: center;margin-top: 15px">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="ruleForm.page" :page-sizes="[10, 20, 30]" :page-size="ruleForm.limit"
                     layout="total, sizes, prev, pager, next, jumper" :total="ruleForm.total">
      </el-pagination>

    </div>
    <el-dialog title="编辑信息" :visible.sync="dialogVisible" width="30%" :before-close="handleClose" conten>
      <el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign">
        <el-form-item label="姓名">
          <el-input style="width: 60%;" v-model="formLabelAlign.name"></el-input>
        </el-form-item>
        <el-form-item label="电话号码">
          <el-input style="width: 60%;" v-model="formLabelAlign.tel"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">关闭</el-button>
        <el-button @click="delete_Reco">修改</el-button>
			</span>
    </el-dialog>

  </div>
</template>

<script>
import {
  getmarket,
  market_addpage,
  user_service
} from '@/api/layout'

export default {
  name: 'list',
  data () {
    return {
      dialogVisibles: false,
      labelPosition: 'right',
      options: [
        {
          id: 1,
          name: '市场自查'
        },
        {
          id: 2,
          name: '监管人员巡查'
        }
      ],
      tadoDatas: [],
      tadoData: [],
      provinceids: [],
      category: [],
      provinceid: [
        {
          key: 0,
          val: '是'
        },
        {
          key: 1,
          val: '否'
        }
      ],
      todolist: [
        {
          id: 2,
          name: '已巡查待确认'
        },
        {
          id: 3,
          name: '已巡查待整改'
        },
        {
          id: 4,
          name: '已巡查已确认'
        },
        {
          id: 5,
          name: '已巡查已整改'
        }
      ],
      // 条件筛选字段
      ruleForm: {
        mk_id:"",
        page: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      dialogVisible: false,
      formLabelAlign: {
        name:"",
        tel:""
      },
      marketid: [],
      ids: ''
    }
  },
  created () {
    this.init()
    this.get_market()
  },
  methods: {
    // 初始化数据接口
    init () {
        user_service(this.ruleForm).then(res => {
          this.tableData = res.data.data
          this.ruleForm.total = res.data.total
        })
    },
    // 市场
    get_market () {
      getmarket().then(res => {
        if (res.code === 200) {
          this.marketid = res.data
          this.init()
        }
      })
    },
    // 获取单位类型
    Get_Roles () {
      getRoles().then(res => {
        this.provinceids = res.data
      })
    },

    goto_Roles (value) {
      this.ruleForm.patrol_company_id = ''
      get_PatrolCompany({
        user_type: this.ruleForm.user_type
      }).then(res => {
        this.prov_xuncha = res.data
      })
    },
    goto_options (valeu) {
      console.log(valeu)
      this.ruleForm.inspection_category_id = ''
      get_InspectionCategorys({
        type: this.ruleForm.type
      }).then(res => {
        this.category = res.data
      })
    },
    delete_text (num) {
      this.dialogVisibles = true
      this.ids = num
      console.log(num)
    },
    delete_Reco () {
      let ref = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
      if(!ref.test(this.formLabelAlign.tel)) {
        return this.$message.error('请输入正确的手机号码')
      }
      market_addpage(this.formLabelAlign, this.ids).then(res => {
        this.dialogVisible = false
        this.$message.success(res.data)
        this.init()
      })
    },
    handleClose () {
      this.dialogVisible = false
    },
    beforeAvatarUpload (file) {
      const isJPG1 = file.type === 'image/png'
      const isJPG2 = file.type === 'image/jpeg'
      const isJPG3 = file.type === 'image/bmp'
      const isJPG4 = file.type === 'image/gif'
      const img = [isJPG1, isJPG2, isJPG3, isJPG4]
      const some = img.some(item => {
        return item
      })
      if (!some) {
        this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
      }
      return some
    },
    // 获取真实的路径
    uploadSectionFile2 (param) {
      // this.ruleForm.device_positive_pic = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.device_positive_pic = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 分页功能
    handleSizeChange (val) {
      this.ruleForm.page = 1
      this.ruleForm.limit = val
      this.init()
    },
    handleCurrentChange (val) {
      this.ruleForm.page = val
      this.init()
    },
    // 查看明细
    query (item) {
      this.ids=item.id
      this.dialogVisible = true
     this.formLabelAlign.name=item.name
      this.formLabelAlign.tel=item.tel
    }
  }
}
</script>

<style lang="less" scoped>
.el-icon-plus {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
</style>
